import React, { JSX } from 'react';

import {
  TaskRounded,
  HelpRounded,
  CloudRounded,
  FileOpenRounded,
  CategoryRounded,
  SummarizeRounded,
  TableChartRounded,
  AccessTimeRounded,
  AutoAwesomeRounded,
  ConstructionRounded,
  FolderSpecialRounded
} from '@mui/icons-material';

import { ReadInteractionTypeSchema } from '@/helpers/services/api';

export const colorsList = [
  '#9159F7',
  '#00B2C2',
  '#02A5E6',
  '#C23EC0',
  '#367BF4',
  '#DD268D',
  '#2A0B63',
  '#5C36A4',
  '#B28FF3',
  '#2052AA',
  '#79A7F8',
  '#007681',
  '#016E99',
  '#47C9FD',
  '#822980',
  '#95175E',
  '#95175E'
];

export const iconsDictionary: { [key: number]: JSX.Element } = {
  1: <HelpRounded />,
  2: <ConstructionRounded />,
  3: <SummarizeRounded />,
  4: <CategoryRounded />,
  5: <AutoAwesomeRounded />,
  6: <TaskRounded />,
  7: <FileOpenRounded />,
  8: <TableChartRounded />,
  10: <AccessTimeRounded />,
  11: <CloudRounded />,
  12: <FolderSpecialRounded />
};

export const constants = {
  label: 'Interaction Type',
  types: [
    { name: 'Q&A', color_code: `${colorsList[0]}`, icon_id: 1, id: NaN, is_builtin_type: true, is_active: false },
    { name: 'Other', color_code: `${colorsList[1]}`, icon_id: 2, id: NaN, is_builtin_type: true, is_active: false },
    {
      name: 'Summarization',
      color_code: `${colorsList[2]}`,
      icon_id: 3,
      id: NaN,
      is_builtin_type: true,
      is_active: false
    },
    {
      name: 'Classification',
      color_code: `${colorsList[3]}`,
      icon_id: 4,
      id: NaN,
      is_builtin_type: true,
      is_active: false
    },
    {
      name: 'Generation',
      color_code: `${colorsList[4]}`,
      icon_id: 5,
      id: NaN,
      is_builtin_type: true,
      is_active: false
    },
    {
      name: 'Feature Extraction',
      color_code: `${colorsList[5]}`,
      icon_id: 6,
      id: NaN,
      is_builtin_type: true,
      is_active: false
    }
  ] as ReadInteractionTypeSchema[]
};
