import React, { useEffect, useState } from 'react';

import { useRouter } from 'next/router';

import { alpha, Stack } from '@mui/material';
import { KeyboardArrowDownOutlined, KeyboardArrowUpOutlined } from '@mui/icons-material';

import { StyledButton, StyledText } from '@/components/lib';
import { InteractionTypeDDContainer, StyledInteractionsTypePopover } from './InteractionTypeDropDown.styles';

import { ReadInteractionTypeSchema } from '@/helpers/services/api';
import { getStorageItem, setStorageItem, storageKeys } from '@/helpers/utils/localStorage';

import { constants, iconsDictionary } from './interactionTypes.constants';

interface InteractionTypeDropDownProps {
  noLabel?: boolean;
  outline?: boolean;
  interactionType: string;
  appInteractionTypes?: ReadInteractionTypeSchema[];
  additionalCallback?: () => void;
  setInteractionType: (value: string) => void;
}

const { label, types } = constants;

export const InteractionTypeDropDown = (props: InteractionTypeDropDownProps) => {
  const { noLabel, outline, interactionType, appInteractionTypes, additionalCallback, setInteractionType } = props;

  const { query, pathname, replace } = useRouter();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const appStorage = getStorageItem(storageKeys.app);

  const noAppInteractionTypesFallback = Array?.isArray(appStorage?.interactionType?.options)
    ? appStorage?.interactionType?.options
    : types;

  const availableTypes: ReadInteractionTypeSchema[] = appInteractionTypes || noAppInteractionTypesFallback;

  const activeTypes = Array?.isArray(availableTypes) ? availableTypes?.filter(val => !!val?.is_active) : types;

  // Important to check if activeTypes is an array, otherwise it will throw an error (caching issue)
  const activeTypesSafeList = Array?.isArray(activeTypes) ? activeTypes : [];

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(anchorEl ? null : event?.currentTarget);

  const handleChooseType = (type: string) => {
    try {
      if (type !== interactionType) {
        setAnchorEl(null);
        setInteractionType(type);
        setStorageItem(storageKeys.app, {
          ...appStorage,
          interactionType: { options: availableTypes, value: type }
        });

        additionalCallback && additionalCallback();
      } else {
        setAnchorEl(null);
      }
    } catch (error) {
      setAnchorEl(null);
    }
  };

  useEffect(() => {
    try {
      if (!interactionType || !availableTypes?.find(type => type?.name === interactionType)) {
        setInteractionType(availableTypes?.[0]?.name);
        setStorageItem(storageKeys.app, {
          ...appStorage,
          interactionType: {
            value: availableTypes?.[0]?.name,
            options: availableTypes
          }
        });
      } else {
        replace({ pathname, query: { ...query, interactionType: interactionType } }, undefined, {});
      }
    } catch (error) {
      setInteractionType(types?.[0]?.name);
    }
  }, [interactionType, appInteractionTypes]);

  try {
    return (
      <>
        <InteractionTypeDDContainer outline={!!outline}>
          {!noLabel && <StyledText text={label} type="bodyBold" />}
          <StyledButton
            endIcon={
              anchorEl ? <KeyboardArrowUpOutlined fontSize="large" /> : <KeyboardArrowDownOutlined fontSize="large" />
            }
            label={interactionType}
            startIcon={iconsDictionary?.[Number(activeTypes?.find(type => type?.name === interactionType)?.icon_id)]}
            onClick={handleOpen}
          />
        </InteractionTypeDDContainer>
        <StyledInteractionsTypePopover open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
          <Stack>
            {activeTypesSafeList?.map(type => (
              <StyledButton
                key={type.id}
                label={type.name}
                startIcon={iconsDictionary?.[Number(type?.icon_id)]}
                sx={{
                  padding: '8px 12px !important',
                  color:
                    type?.name?.toLowerCase() === interactionType?.toLowerCase()
                      ? `${type?.color_code} !important`
                      : 'inherit',
                  background:
                    type?.name?.toLowerCase() === interactionType?.toLowerCase()
                      ? `${alpha(`${type?.color_code}`, 0.2)} !important`
                      : 'transparent',

                  '&:hover': { background: `${alpha(`${type?.color_code}`, 0.2)} !important`, boxShadow: 'none' }
                }}
                onClick={() => handleChooseType(type?.name)}
              />
            ))}
          </Stack>
        </StyledInteractionsTypePopover>
      </>
    );
  } catch (error) {
    return <Stack />;
  }
};
