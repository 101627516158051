export const constants = {
  input: 'IN',
  inputTooltip: 'Input',
  output: 'OUT',
  outputTooltip: 'Output',
  custom: 'CUSTOM',
  customTooltip: 'Custom Property',
  llm: 'LLM',
  llmTooltip: 'LLM Property'
};
